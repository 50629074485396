.btn{
  font-weight: 500;
  letter-spacing: 1px;

  .badge-green{
    border-radius: 5px;
    border: 1px solid #22de36;
    background: #22de36;
    color: #fff;
  }
}

#partner-results{
  --accent-color: #70898d;
  --accent-color-lighter: #819fa3;
  --title-color: #5f7477;
  
  .pre-name-header {
    font-size: 12px;
    letter-spacing: 1px;
  }

  .btn{
    font-weight: 500;
    letter-spacing: 1px;

    .badge-green{
      border-radius: 5px;
      border: 1px solid #22de36;
      color: #22de36;
    }
  }

  .pageHeader {
    background-color: #F6F6F6;
    .badge{
      display: block;
    }
    
    .badge-green{
      border-radius: 5px;
      border: 1px solid #22de36;
      color: #22de36;
    }
  
    .badge-yellow, .badge-orange{
      border-radius: 5px;
      border: 1px solid #FAA240;
      color: #FAA240;
    }
  
    .badge-red{
      border-radius: 5px;
      border: 1px solid #D51D2E;
      color: #D51D2E;
    }
  
    h2{
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 1px;
    }

    h4{
      font-weight: 500;
    }
  }

  .resultsTabList{
    background-color: #F6F6F6;
  }
  
  .btn-primary{
    background: transparent;
    border: 1px solid #70898D !important;
    color: #70898D !important;
    border-radius: 30px !important;

    &:hover{
      background: #70898D !important;
      color: #fff !important;
      border: 1px solid #70898D !important;
    }
  }

  .result-filter{
    position: relative;
    
    select{
      position: relative;
      z-index: 999;
      background-color: transparent;
      padding-left: 75px;
      padding-right: 25px;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 14px;
      color: #70898D;
      height: 100%;
      border: 1px solid #70898D;
      border-radius: 10px;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      background-image: url("/img/br_down.png");
      background-repeat: no-repeat;
      background-position-x: 94%;
      background-position-y: 11px;
      background-size: 10px;

      &:focus{
        background-color: #transparent;
      }
    }
  
    label{
      position: absolute;
      top: 8px;
      left: 23px;
      text-transform: uppercase;
      font-weight: 300;
      font-size: 14px;
      color: #70898D;
      letter-spacing: 1px;
      z-index: 99;
    }
  }

  .partnerCard{
    border-radius: 10px;
    border: 1px solid #D8D8D9;
    margin: 10px; 
    flex-flow: column wrap;
    min-height: 450px;
    max-height: 600px;

    &:hover {
      box-shadow: 0 0 10px #e2e2e2;
      cursor: pointer;
    }

    &.dark{
      background-color: #364B4E;
      color:white;
      cursor: initial;


      .partnerCard-header{
        background-color: #445C60;
      }

      .partnerCard-body p{
        color: #B4CCD0;
      }
      
    }

    .partnerCard-header{
      background-color: #F6F6F6;
      margin: 10px;

      .title{
        color: #70898D;
      }
    }

    .partnerCard-body{
      flex: 1 1 100%;
      margin: 10px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      
      .box-shadow{
        box-shadow: none;
      }

      img{
        width: 120px;
      }

      .chart{
        // padding: 0px;
        max-width: 80%;
        height: 100%;

        li{
          height: 300px;
        }
      }

      .border{
        border: none !important;
      }
    }

    .partnerCard-footer{
      margin-bottom: 25px;
    }

  }

  .doctorComment-section{

    .doctorComment-header{
      display: flex;
      align-items: center;

      img{
        width: 65px;
      }

      p{
        font-size: 1rem;
        font-weight: 200;
        margin: 0px;
        padding-left: 5px;
      }
    }

    .doctorComment-footer{
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      background-color: #F6F6F6;
      padding: 15px;
  
      p{
        font-weight: 400;
      }
  
      .custom-switch .custom-control-label::before{
        top: 0;
      }
  
      .custom-control-input:checked ~ .custom-control-label::before{
        background-color: #70898D;
        border-color: #70898D;
      }
  
      .custom-switch .custom-control-label::after{
        top: 2px;
      }
  
      .custom-control label:first-of-type{
        position: absolute;
        top: 0;
        left: 38%;
      }

      @media screen and (max-width: 991px){
        .custom-control label:first-of-type{
          left: 30%;
        }
      }

      @media screen and (max-width: 575px){
        .custom-control label:first-of-type{
          left: 36%;
        }
      }
  
      .custom-control{
        padding-left: calc(45px + 1.5rem);
      }
    }
  }

  .backToTop{
    bottom: 80px;
  }

  .cursor-auto{
    cursor: auto !important;
  }

  .biomarkers-failed{
    border-bottom: 1px solid #dee2e6;
    padding-block: 5px ;

    .chartContainer{
      position: relative;
      max-width: 30px;
      width: 100%;
      margin: 0;

      .chartValue{
        position: absolute;
        top: 0;
        left: 0;
        color: white;
        transform: translateY(50%) translateX(110%)
      }

      &.red {
        svg{
          fill: red;
        }
      }
    }
  }
}