#marker-result-title {
  padding: 50px 0;
  .overviewDate{
    a{
      font-size: 12px;
      display: inline ;
    }
  }
  h1 {
    display: inline;
    padding-top: 30px;
  }
  a {
    display: block;
    font-size: 15px;
    padding: 0;
    text-decoration: none;
    &.left {
      margin-right: 40px;
    }
    &.right {
      margin-left: 40px;
    }
  }
}

.break {
  border-left:1px solid #ffffff;
  height:50px;
  width: auto;
}

.bioFooter{
  position: fixed;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: 2%;
  width: 60%;
  height:50px;
  background-color: #3e3e3e;
  z-index: 1000;
  border-radius: 100px;
  border: 1px solid #ffffff;

  
}
.bioIcon{
  font-size: 25px;
  vertical-align: sub;
}
.footerText {
  color: white;
  font-size: 15px;
  margin-bottom: 0;
  cursor: pointer;
  flex-direction: column
  
}
.bioModal{

  .chartDrop {
    position: relative;
    top: 0;
    width: 30px;
    left: 0;
    fill: #f0f2f3;
    &.Low, &.High {
      fill: #ff0000;
    }
    &.Borderline {
      fill: #fc8e0f;
    }
    &.Normal {
      fill: #4ab724;
      .chartValue {
        display: block;
      }
    }
  }
  .chartValue {
    display: none;
    color: white !important;
    font-size: 11px !important;
    position: absolute !important;
    width: 100% !important;
    text-align: center !important;
    padding-top: 9px !important;
    top: 0;
    left: 0;
  }

  .modalText{
    margin-top: 2px;
  }

}

.desktop{
  display: block;
}
.mobile{
  display: none !important
}

@media only screen and (max-width: 600px) {

  .desktop{
    display: none;
  }
  .mobile{
    display: block !important
  
  }
}

#detailed-progress {

  .referenceRangeTitle{
    font-size:12px;
    width:100%;
    text-align:center;
    padding:15px 0;
    margin-top:10px;
  }

  .mean-platelet-volume-chart {
    margin-top: 35px;
  }

  .markerRange .chartTitle p{
    color: var(--font-color);
  }

  .chartContainer .averageMarker + .mean-platelet-volume-chart{
    margin-bottom: 58px;
  }

  .chartContainer .valuePosition {
    // height: 150px;
    .chartDrop {
      bottom: -15px;
      top: initial;
      .chartValue {
        top: 4px;
      }
    }
  }
  .chartContainer .valuePosition:before {
    // height:140px;
  }
  .averageMarker {
    float:left;
    position: relative;
    z-index: 999;
    top: 2px;
    margin-left:-5px;
    
    &::before{
      position: absolute;
      top: 38px;
      content: "";
      width: 1px;
      height: 30px;
      border: 1px dashed var(--title-color);
    }

    &::after{
      position: absolute;
      content: "";
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: var(--title-color);
      top: 67px;
      left: -4px;
    }

    img {
      height: 240px;
      display: none;
    }

    .averageValue {
      position: absolute;
      width: 87px;
      left: -40px;
      top: 85px;
      font-size: 12px;
      text-align: center;
      .thickText{
        color: var(--title-color);
      }
    }
  }
  .valueText {
    position: absolute;
    width: 131px;
    left: -53px;
    font-size: 12px;
    text-align: center;
    top: -44px;
  }
  .indicator {
    font-size: 14px;
  }
  
  .label .green, .indicator.green {
    color: var(--green);
  }

  .label .orange, .orange {
    color: var(--yellow);
  }

  .label .red, .indicator.red {
    color: var(--red);
  }

  .label .grey, .indicator.grey {
    color: var(--grey);
  }
  .label {
    position: absolute;
    width: 100%;
    top: -40px;
    text-align: center;
    font-size: 10px;
    line-height: 12px;
  }
  .alert {
    margin: 0 40px;
  }
  .markerInformation {
    margin-top: 10px;
    h2 {
      font-size: 16px;
      margin-bottom: 5px;
    }

    p{
      font-weight: 300;
    }
  }

  .averageDetails {
    font-size:12px;
    text-align:center;
    font-style:italic;
    color:#9e9c9c;
  }




}

#detailed-progress {
  
  .alert-danger .fa-check {
    display:none;
  }
  .alert-warning .fa-check {
    display:none;
  }

  .chartDrop {

    .red .fa-check {
    display:none;
    }
    .orange .fa-check {
      display:none;
    }
  }

  .textBasedResult {
    background:#000000;
  }

}




@media only screen and (max-width: 576px) {
  #detailed-progress {
    .mean-platelet-volume-chart {

      .valueText {
        top: 70px;
      }

      .averageMarker {
        img {
          height: 140px;
        }
      }
    }
  }
}

#your-result-progress {
  .chart-y-axis {
    margin-left: 0;
    padding-left: 0;
    // padding-top:31px;
    padding-bottom:44px;
    padding-right: 5px;
  }
  .improved-markers-chart {
    padding: 1px;
    padding-bottom: 40px;
    margin-top:0px;
    overflow-y:hidden;
    span {
      margin: 0;
    }

    .slick-slider {
      display: table;
      table-layout: fixed;
      width: 100%;
      height: auto;
      margin: 0 auto;
      padding: 0;
      overflow-y: hidden;
      z-index:40;

      .slideOuterTbl {
        padding-top: 4px;
        display: table !important;
        width:100%;

      }

      .chartDate {
        display: table-footer-group;
        position:relative;
        top:0px;
        bottom:0px;
        height:30px;
        padding:0px;

        time {

            display: table-cell;
            vertical-align: middle;

         }

      }

      li {
        width:100%;
        position: relative;
        display: table-cell;
        vertical-align: bottom;
        height: 180px;
        z-index:2;
        border: 1px solid transparent;
      }

      .chartLink {

        display: table-row;
        position:relative;
        top:0px;
        bottom:0px;
        height:40px;
        vertical-align: middle;

         a {
            
            width: 60%;
            display: block;
            margin: 0 auto;
            margin-top: 24px;
            vertical-align: bottom;

         }
      }

    }
    
  }
  .activeResult {
    li {
      border: 1px solid transparent !important;
    }
    .chartDate {
      border-radius: 10px;
      background: var(--accent-color);
      color: white;
    }
    .chartLink a {
      display:none !important;
    }
  }
  .slick-dotted.slick-slider {
    margin-bottom: 15px;
  }
  .slick-prev, .slick-next {
    top: initial;
    bottom: -50px;
  }
  .chartDate {
    top:-29px;
    bottom:auto;
    padding:7px 0;
  }
  .chartLink {
    position: absolute;
    bottom: -58px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    height: 35px;
    padding: 0 5px;
  }
  .chartValue {
    z-index:20;
  }
}

.slick-dots {
  li {
    position: relative !important;
    display: inline-block !important;
    width: 20px !important;
    height: 20px !important;
  }

}

#about-marker {
  padding: 50px 10%;
}

.modal-body {
  
  #about-marker {
    padding: 0 5%;
    max-width:1000px;
    margin:0 auto;
  }

}

.icon-line-break {
  text-align: center;
  overflow: hidden;
  position: relative;
  margin: 30px 0px;
  svg{
    font-size: 21px;
    border: 1px solid var(--accent-color);
    color: var(--accent-color-lighter);
    border-radius: 360px;
    width: 30px;
    height: 30px;
    padding: 10px;
    display: inline-block;

  }
  &:before, &:after {
    position: absolute;
    top: 51%;
    overflow: hidden;
    width: 50%;
    height: 1px;
    content: '\a0';
    background-color: var(--accent-color);
  }
  &:before {
    margin-left: -50%;
    text-align: right;
  }
}

#about-marker {
  word-wrap: break-word;
  h3 {
    text-align: center;
    margin-bottom: 30px;
  }

  p{
    font-weight: 300;
  }

  ul {
    padding-left: 30px;
    list-style: none;
    li {
      margin-bottom: 15px;
      &::before {
        content: "\2022";
        color: var(--accent-color-lighter);
        font-weight: bold;
        display: inline-block;
        width: 1em;
        margin-left: -1em;
      }
    }
  }
  h4 {
    text-transform: uppercase;
    span {
      vertical-align: middle;
      display: inline-block;
    }
    svg{
      margin-right: 10px;
      color: var(--accent-color-lighter);;
      font-size: 30px;
    }
  }
}

@media only screen and (max-width: 992px) {
  #detailed-progress .alert {
    margin: 0;
    svg{
      position: initial;
    }
  }
  #your-result-progress {
    .improved-markers-chart {
      padding-bottom: 80px;
    }
    .slick-dots {
      bottom: 0;
    }
    .slick-prev, .slick-next {
      top: initial;
      bottom: -15px;
    }
  }
}

@media only screen and (max-width: 576px) {
  .mobileSlider {
    .bg-white, .slick-slide {
      height: auto;
    }
    .slick-dots {
      bottom: -35px;
    }
  }
  #your-result-progress {
    .improved-markers-chart {
      padding-bottom: 20px;
    }
  }
}

#markers {
  .chartContainer {
    margin:0px;
  }
}

.categoryPageTitle {
  color: var(--font-color);
  padding-top: 40px;
  padding-bottom: 40px;

  img {
    font-size:40px;
    margin-bottom: 20px;
    color: var(--primary-color);
    // filter: invert(61%) sepia(75%) saturate(254%) hue-rotate(127deg) brightness(130%) contrast(89%);
    filter: invert(61%) sepia(163%) saturate(664%) hue-rotate(190deg) brightness(120%) contrast(44%);
    width: 80px;
  }

}

.noResults {
  min-height:500px;
  padding-top:60px;

  p {
    font-size:20px;
    line-height:25px;
    font-weight: 300;
  }

  .btn {
    margin-top:50px !important;
  }
}


.exportReultsModal {
  --accent-color: #70898d;
  --accent-color-lighter: #819fa3;
  --bg-color: #fff;

  h4 {
    font-weight:500;
    color: var(--font-color);
    text-align:center;
    font-size:20px;
  }

  select {
    background-color: white;
  }

  label {
    width: 100%;
    border: 1px solid var(--accent-color);
    border-radius: 10px;
    text-align: center;
    color: var(--font-color);
    font-size: 14px;
    text-transform: uppercase;

    input[type="radio"] {
      appearance: none;
      display: none;
    }
    &:has(input[type="radio"]:checked) {
      background: var(--accent-color);
      color: white;
      font-weight: 500;
      span {
        color: white;
        font-weight: 500;
      }
    }
    &:not(.exportOption):hover:not(:has(input[type="radio"]:checked)) {
      background: #c0d5d8;
    }

    &.exportOption {
      background: var(--accent-color);
      border:none;
      color: white;
    }
  }

  .dropdownSelector {
    -webkit-appearance: button;
    -webkit-border-radius: 2px;
    -webkit-box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-padding-end: 20px;
    -webkit-padding-start: 2px;
    -webkit-user-select: none;
    background: url(../img/br_down.png) no-repeat right #ddd;
    -webkit-appearance: none;
    background-position-x: 270px;
    background-position: 97% center;
    background-repeat: no-repeat;
    border: 1px solid #AAA;
    color: #555;
    font-size: inherit;
    overflow: hidden;
    padding: 5px 10px;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 300px;
    padding:10px;

  }

}

.textBasedResultIcon {
  max-height:320px;
  max-width: 150px;
  width: 100%;
  padding: 10%;
  display:inline-block !important; 

  &.bespokeMaxHeight {
    max-width:40px;
    padding:0 0 15px 0;
  }
}


.rating {
  background-color: #F0F2F3;
  border-radius: 5px;
  p {
    color: #666 !important;
    font-size: 20px !important;
  }
  textarea{
    font-size: 12px;
    font-weight: 300;
  }

  textarea:focus{
    color: black;
    background: white !important;
  }

  .alert{
    color: #ffffff !important ;
    font-size: 12px !important;
  }

  .trustpilot-review{
    h2{
      font-size: 25px !important;
      font-weight: 300;
    }

    p{
      font-size: 20px !important;
      font-weight: 200;
      line-height: 25px !important;
    }

    a{
      color:#5abbb4;
      text-decoration: none;
    }
  }
}

.otherBiomarkers-modal{
  max-width: 90%;
}