#partnerTables{

  /* 
    Be aware .table and .table-responsive are rotated 180 degrees to display the scrollbar on the top
    If you need to align something vertically you have to do it up side down.
  */

  .modal {
    z-index: 9999!important;
  }

  .table{
    --bs-table-hover-bg: #F6F6F6;
    --bs-table-striped-bg: #F6F6F6;
  }

  table{
    display: table;
    table-layout: auto;
    width: 100% !important;
    min-width: 500px;
    background-color: #fff;
    border: 1px solid #E6E6E6 !important;
    border-radius: 5px;
    border-collapse: separate;
    -webkit-border-horizontal-spacing: 0;
    -webkit-border-vertical-spacing: 0;

    thead th{
      text-transform: uppercase;
      text-wrap: nowrap;
      padding: 15px;
      color: #70898D;
      position: relative;
      cursor: pointer;
      transition: all 0.2s ;

      &.active{
        color: #000;
        text-decoration: underline;
        text-decoration-thickness: 5px;
        text-underline-offset: 12px;
        text-decoration-color: #445C60;
      }

      &:hover{
        color: #000;
      }

      &.th-5{
        max-width: 200px;
        min-width: 200px;
        width: 200px;
      }
      &.th-4{
        max-width: 170px;
        min-width: 170px;
        width: 170px;
      }
      &.th-3{
        max-width: 150px;
        min-width: 150px;
        width: 150px;
      }
      &.th-2{
        max-width: 120px;
        min-width: 120px;
        width: 120px;
      }
      &.th-1{
        max-width: 100px;
        min-width: 100px;
        width: 100px;
      }
      &.th-auto{
        width: min-content;
      }

    }

    tbody tr:hover:first-child *{
      --bs-table-accent-bg: white !important;
    }

    tbody tr, tbody tr td{
      max-height: 80px;
    }

    td, th{
      display: table-cell;
    }

    tr{
      display: table-row;

      &.tr-highlight{
        background-color: var(--bs-table-striped-bg);
      }
    }

    td{
      vertical-align: middle;
      padding: 20px;
      overflow-wrap: break-word;

      &.td-6{
        max-width: 220px;
        min-width: 220px;
        width: 220px;
      }
      &.td-5{
        max-width: 200px;
        min-width: 200px;
        width: 200px;
      }
      &.td-4{
        max-width: 170px;
        min-width: 170px;
        width: 170px;
      }
      &.td-3{
        max-width: 150px;
        min-width: 150px;
        width: 150px;
      }
      &.td-2{
        max-width: 120px;
        min-width: 120px;
        width: 120px;
      }
      &.td-1{
        max-width: 100px;
        min-width: 100px;
        width: 100px;
      }
      &.td-auto{
        width: min-content;
      }
    }

    .actionMenu{
      .actionButton {
        background-color: transparent;
        padding: 7px 10px;
        border: 1px solid #70898D;
        border-radius: 5px;
        color: #70898D;
        display: flex;
        align-items: center;
        transition: all 0.2s ease;
        &:hover{
          background-color: #70898D;
          color: white;
        }

        &::before{
          display: none;
        }
      }

      .dropdown-menu{
        filter: drop-shadow(0px 5px 3px rgba(0, 0, 0, 0.15));
        background-color: white;
        width: auto;
        
        a{
          padding-inline: 10px;
          &:hover{
            background-color: #F6F6F6;
          }
        }

      }
    }
  }

  .table > :not(:first-child){
    border-top: none;
  }


  input[type="search"]{
    padding-left: 35px;
    position: relative;

    &::placeholder{
      color: #9FB9BD;
    }

    &:focus{
      background-color: transparent !important;
      color: #70898D; 
    }

  }

  .searchInput{
    position: relative;

    &::before{
      position: absolute;
      display: inline-block;
      font-style: normal;
      font-variant: normal;
      text-rendering: auto;
      -webkit-font-smoothing: antialiased;
      font-family: "Font Awesome 6 Pro";
      font-weight: 400;
      font-size: 20px;
      content: "\f002";
      color: #70898D;
      z-index: 99;
      top: 7px;
      left: 11px;
      line-height: initial;
    }

    .filter{
      position: relative;
      
      select{
        padding-left: 81px;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 14px;
        color: #70898D;
        height: 100%;
      }

      label{
        position: absolute;
        top: 10px;
        left: 21px;
        text-transform: uppercase;
        font-weight: 300;
        font-size: 14px;
        color: #70898D;
        letter-spacing: 1px;
      }
    }

    .dropdown-toggle{
      padding-right: 20px;
      &::after{
        position: absolute;
        font-size: 8px;
        margin-inline: 5px;
        top: calc(50% - 6px);
        border: none;
        font-family: "Font Awesome 6 Pro";
        font-weight: 900;
        content: "\f078";
        transition: all 0.2s;
      }
    }

    .dropdown-menu{
      background-color: white;
      top: 5px !important;
      width: max-content !important;
      border: none;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
    }

    .dropdown-menu li{
      margin-bottom: 0px;
      text-align: start;
      
      &:hover{
        background-color: #B4CCD0;
      }

      *:hover{
        background-color: transparent;
      }

      .dropdown-item{
        color: #445C60;
      }
    }
  }

  .btn-filter{
    border-radius: 5px !important;
    width: 15%;
  }

  .icon{
    cursor: pointer;
    color: #70898D;
  }

  a{
    color: #70898D;
    &:hover{
      color: #445C60;
    }
  }

  .badge{
    display: block;
  }
  
  .badge-green{
    border-radius: 5px;
    border: 1px solid #70898D;
    color: #70898D;
  }

  .badge-primary{
    border-radius: 5px;
    border: 1px solid #4e4ee7;
    background: transparent;
    color: #4e4ee7;
  }
  
  .badge-yellow, .badge-orange{
    border-radius: 5px;
    border: 1px solid #FAA240;
    color: #FAA240;
  }

  .badge-red{
    border-radius: 5px;
    border: 1px solid #D51D2E;
    color: #D51D2E;
  }

  .form-select{
    background-color: transparent;
  }

  input[type=radio], input[type=checkbox]{
    border-color: #70898D;
    &:checked{
      background-color: #70898D !important;
      border-color: #70898D !important;
    }
    
    &:focus{
      border-color: #70898D !important;
    }
  }
}

#orderDetailsModal {
  .yellowTube {
    color: #f0ad4e;
  }
  
  .purpleTube {
    color: #7b61ff;
  }
}

.table-responsive{
  min-height: 570px;
  display: flex;
  flex-direction: column;
}

.pagination{
  .page-link{
    color:#445C60 !important;
  }

  .active{
    .page-link{
      background-color: #445C60;
      color:white !important;
    }
  }
  .page-item:disabled{
    color: blue;
    background: #ccc;
    cursor: none;
  }
}

.tableFilter{
  overflow: hidden;
  transition:max-height 0.3s ease-out;
}

.filter-box{
  margin: 5px;
  padding: 5px 10px;

  &:not(:last-child){
    border-right: 1px solid #E6E6E6;
  }

  h3{
    font-weight: 400;
  }

  select{
    color: inherit;
  }

}

.filter-close{
  max-height: 0px;
}

.filter-open{
  height:auto;
  max-height:600px;
}

.column-2{
  columns: 2;
}

.subscription-accordion {
  .accordion-title{
    font-size: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    padding-bottom: 20px;
  } 
  
  .accordion-header p{
    font-size: 0.9rem;
    color: #666666;
  }
  
  .accordion-body {
    display: flex;
    flex-direction: column;
    padding: 3px 15px;
  
    .row div{
      background-color: #e6eef0;
      text-transform: uppercase;
      padding: 10px;
      margin: 1px;
    }
  
    .row div:first-child{
      letter-spacing: 0.5px;
      font-weight: 500;
    }
  
    .row.cancel div{
      background-color: var(--bs-red);
      font-weight: 500;
      // cursor: pointer;
      text-align: center;
  
      a {
        color: white !important;
      }
    }
  }
}
