#partnerClients, #partnerAccount, #partnerSettings, #partnerTests, #partnerOrders, #partnerResults, #healthCoach{

	.form-success{
    text-align: center;
		padding: 1rem;
		background-color: rgb(200, 253, 200);
		color: green;
  }
  
  .form-error{
    text-align: center;
		padding: 1rem;
		background-color: rgb(255, 174, 174);
    color: red;
    font-weight: 700;
	}


  .bg-gray{
    background-color: #F6F6F6;
  }
  
  .btn{
    font-weight: 500;
    letter-spacing: 1px;
  }

  .btn-primary{
    background: transparent;
    border: 1px solid #70898D;
    border-radius: 30px !important;
    color: #70898D !important;
    
    &:hover{
      border: 1px solid #70898D !important;
      background: #70898D !important;
      color: #fff !important;
    }
  }

  .btn-secondary{
    background: #70898D;
    border: 1px solid #70898D !important;
    border-radius: 30px !important;
    color: #fff !important;

    &:hover{
      background: #445C60 !important;
      border: 1px solid #000 !important;
      color: #fff !important;
    }
  }

  .btn-add{
    width: 15%;
  }

  .btn-simple{ 
    border: none !important;
    text-decoration: underline;
    background-color: transparent;
    color: #70898D !important;

    &:hover{
      background-color: transparent !important;
      color: #445C60 !important;
    }
  }

  .btn-outline-danger{
    color: red !important;
    border: 1px solid red !important;
    background: transparent !important;

    &:hover{
      background: red !important;
      color: white !important;
    }
  }

  .text-uppercase-first{
    text-transform: lowercase;
    &:first-letter{
      text-transform: uppercase;
    }
  }

  .pageHeader {
    background-color: #F6F6F6;

    .badge{
      display: block;
    }
    
    .badge-green{
      border-radius: 5px;
      border: 1px solid #22de36;
      background: #22de36;
      color: #fff;
    }
     
    
    h2{
      font-size: 24px;
      font-weight: 500;
      letter-spacing: 1px;
    }

    h4{
      font-weight: 500;
    }
  }

  .client-section, .profile-section, .account-section, .settings{
    // background: #F0F2F3;
    width: 100%;
    padding: 25px 0px; 
    margin-bottom: 1rem;

    .form-group input{
      height: fit-content;
      padding-top: 25px;
      border: 1px solid #70898D;
      font-weight: 300;
      color: #000;

      &:disabled{
        background-color: #F6F6F6 !important;
        -webkit-appearance: none;
        -moz-appearance: none;
      }

      &:focus{
        background-color: #fff;
      }

      &:hover{
        border-color: #445C60;
      }
    }
    
    .form-group label{
      position: absolute;
      padding-left: 12px;
      padding-top: 5px;
      top: 1px;
      color: #70898D;
      width: 97%;
      background-color: #fff;
      margin-left: 2px;
      &.error {
        color: red;
      }
    }

    .form-group input:disabled{
      background-color: #F6F6F6;
      border-color: #ADAEAF;
      color: #ADAEAF;
    }
    
    .weightLabel ,.heightLabel{
      z-index: 9;
      background: transparent !important;
    }

    label.readOnly{
      background: #e9ecef;
    }

    label.noBackground{
      background: transparent;
    }

    .testUrl:focus{
      background-color: #e9ecef !important;
    }

    label.disabled{
      left: 1px;
      background-color: #F6F6F6 !important;
      border-radius: 3px;
    }

    label.enabled{
      left: 1px;
      background-color: white !important;
      border-radius: 3px;
    }
    
    input:not(:disabled) ~ span.input-group-text{
      border: 1px solid #70898D;
      background: #70898D;
    }

    input:disabled ~ span.input-group-text{
      border: 1px solid #ADAEAF;
      background: #ADAEAF !important;
    }

    .narrow{
      max-height: 50px;
    }

    .pt-6 {
      padding-top: 3.6rem;
    }

    .pt-7{
      padding-top: 4rem;
    }

    .border{
      border-radius: 5px;
      border: 1px solid #70898D !important;
    }

    .bg-disabled{
      background-color: #F6F6F6 !important;
      border: 1px solid #ADAEAF !important;
      label{
        background-color: inherit;
      }
    }

    input[type="checkbox"]{
      width: 25px;
      height: 25px;
      transform: scale(0.7);
      
      &:checked{
        background-color: #70898D !important;
        border-color: #70898D !important;
      }
    }
  }

  .add-client {
    .modal-header{
      border-bottom: none;
      h4 {
        text-transform: uppercase;
        font-size: 20px;
        letter-spacing: 2px;
      }
      svg{
        font-size: 20px;
        &.success {
          color: rgb(0, 187, 0);
        }
        &.danger {
          color: rgb(255, 202, 103);
        }
      }
    }
    .modal-body{
      p{
        font-size: 15px;
        
      }
      a{
        color: #95A9AE;
      }
      .secondary-action{
        background-color: #F6F6F6;
        border: 1px solid #95A9AE;
        border-radius: 25px;
        height: 100%;
        padding: 1.5em;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
    }
  }

  .test-section .labelUrl{
    background-color: transparent !important;
  }

  .subscription-box{
    background-color: #F6F6F6;
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    max-height: 260px;
    min-height: 50px;
    overflow-y: scroll;
    padding: 20px 20px 10px 20px;
  }

  .c-pointer{
    cursor: pointer;
  }

  .badge{
    font-size: 12px !important;
    font-weight: 300 !important;
  }

  table.subscriptions{
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
    position: relative;

    thead {
      position: sticky;
      top: -20px;
      background-color: #F6F6F6;
    }

    th{
      position: sticky;
      bottom: 0;
      vertical-align: middle;
      padding: 15px 10px !important;
    }

    tr:not(:first-child){
      border-top: 2px solid #F6F6F6;
    }

    td{
      background-color: white;
      padding: 10px !important;

      &:last-child(){
        color: #70898D;
      }
    }
  }

  .client-details{
    display: flex;
    width: 100%;
  }

  .tag-section, .access-section{
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 35px 0;

    .tag-list{
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      margin-top: 25px;

      .form-check{
        padding-left: 40px !important;
        padding-bottom: 15px;

        .form-check-input{
          font-size: 25px;
          border-color: #70898D;

          &:checked{
            background-color: #70898D !important;
            border-color: #70898D !important;
          }
        }

        .form-check-label{
          padding-top: 10px;
          margin-bottom: 0;
          text-transform: capitalize;
        }
      }

      .delete-icon{
        font-size: 15px;
        padding: 0 10px;
        &:hover{
          color: #df1d1d;
        }
      }

      .edit-icon{
        font-size: 15px;
        padding: 0 10px;
        &:hover{
          color: var(--bs-orange);
        }
      }
    }

    input[type="text"]{
      border: 1px solid #B4CCD0;
      color: black;

      &::placeholder{
        color:#B4CCD0;
      }

      &:focus{
        background-color: white !important;
        color:black;
      }
    }
  }

  .close::after{
		color: #70898D;
	}

  .testInfo{
    margin-bottom: 75px; 

    .testInfo-header{
      border-bottom: 1px solid #707070 !important;

      .price{
        position: relative;
        font-size: 24px;

        p{
          margin: 0px;
          padding-right: 10px;
        }

        span{
          position: absolute;
          padding-right: 10px;
          color: red;
          font-size: 14px;
          width: fit-content;
          top: -13px;
          right: 0;
          text-decoration: line-through;
        }
      }
    }

    .testInfo-body{
      padding: 15px 10px;
      border-bottom: 1px solid #707070 !important;
      
      label{
        margin-bottom: 0px;
      }

      input[type=radio], input[type=checkbox]{
        border-color: #70898D;
        &:checked{
          background-color: #70898D !important;
          border-color: #70898D !important;
        }
      }
    }

    .testInfo-footer{
      padding: 15px 10px;
      
      label{
        margin-bottom: 0px;
      }

      input[type=radio], input[type=checkbox]{
        border-color: #70898D;
        &:checked{
          background-color: #70898D !important;
          border-color: #70898D !important;
        }
      }
    }
  }

  @media (max-width: 992px) {
    .testInfo{
      margin-bottom: 30px;
    }
  } 
}

#partnerSettings{
  .partner .banner{
    max-height: 500px;
    margin-top: 0px;

    .bannerImg img{
      max-width: 100%;
    }

    .caption{
      h1{
        font-size: 50px;
      }

      p{
        font-size: 13.4px;
      }
    }
  }

  @media (max-width: 991px) {
    .partner .banner {
      max-height: 595px;

      .caption{
        p{
          line-height: 17px;
          font-size: 13.4px;
        }
      }
    }
  }

  @media (max-width: 576px) {
    .partner .banner {
      max-height: 510px;

      .caption{
        p{
          line-height: 17px;
          font-size: 13.4px;
        }
      }
    }
  }
}